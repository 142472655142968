/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Roboto&display=swap');
.validation{
  color: #C80000 !important;
}
.mat-mdc-snack-bar-container {
  &.mat-toolbar {
    --mdc-snackbar-container-color: #fff !important;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.custom-cover {
  max-height: 300px;
  object-fit: cover;
}

